<contract-modal #contractModal [company]="company"></contract-modal>

<div class="loading" *ngIf="loading">
  <spinner message="{{ 'GLOBAL.LOADING' | translate }}"></spinner>
</div>

<div *ngIf="!loading && showRequestedFixations" class="ag-header-title">
  <div id="viewmode-toggle" class="btn-group" data-toggle="buttons">
    <label class="btn btn-default btn-sm" [class.active]="viewMode === 'fixations'" title="Fijaciones">
      <input name="viewmode" type="radio" [(ngModel)]="viewMode" value="fixations" />
      <i class="material-symbols-rounded">view_agenda</i>
    </label>
    <label class="btn btn-default btn-sm" [class.active]="viewMode === 'fixation-requests'"
      title="Solicitudes de Fijación">
      <input name="viewmode" type="radio" [(ngModel)]="viewMode" value="fixation-requests" />
      <i class="material-symbols-rounded">price_change</i>
      {{ "FIXATIONS.REQUEST_MODAL.REQUEST_FIXATIONS" | translate }}
    </label>
  </div>
</div>

<ng-container *ngIf="!loading">
  <ng-container *ngIf="viewMode === 'fixations'">
    <div class="horizontal-scroller fixation-table-container">
      <div class="table-sticky-headers">
        <table class="table table-condensed table-hover" sortable #tableSortable="tableSortable"
          [variableInUrl]="!predefinedFilters" [(ngModel)]="selected" ngDefaultControl>
          <thead>
            <tr class="tr-align-center">
              <th class="sap" sortBy="date" *ngIf="!hideColumns.includes('date')">
                {{ "GLOBAL.DATE" | translate }}
              </th>
              <th class="sap" sortBy="contract.reference" *ngIf="!hideColumns.includes('contract_reference')">
                {{ "GLOBAL.CONTRACT" | translate }}
                <column-filter *ngIf="!predefinedFilters" type="string" alignment="left" [filters]="filters"
                  key="filters[contract_reference]" (onChange)="changeFilter($event)"></column-filter>
              </th>
              <th *ngIf="!hideColumns.includes('slate')">
                {{ "CONTRACTS.SLATE" | translate }}
              </th>
              <th class="dlb drb sap" *ngIf="!hideColumns.includes('price')">
                {{ "GLOBAL.PRICE" | translate }}
              </th>
              <th class="drb sap" *ngIf="!hideColumns.includes('volume')">
                {{ "FUTURES.VOLUME" | translate }}
              </th>
              <th class="sap" *ngIf="!hideColumns.includes('slate_price')">
                {{ "CONTRACTS.SLATE_PRICE" | translate }}
              </th>
              <th class="sap" *ngIf="!hideColumns.includes('exchange_rate')">
                {{ "FUTURES.EXCHANGE_RATE" | translate }}
              </th>
              <th class="sap" *ngIf="!hideColumns.includes('due_date')">
                <abbr [tooltip]="'GLOBAL.DUE_DATE' | translate" [adaptivePosition]="false">{{ "GLOBAL.DUE_DATE_ABBR" |
                  translate }}</abbr>
              </th>
              <th class="sap hidden-xs" *ngIf="!hideColumns.includes('observations')"></th>
              <th class="sap text-center" *ngIf="!hideColumns.includes('source')">
                <abbr tooltip="{{ 'GLOBAL.SOURCE' | translate }}" [adaptivePosition]="false">
                  <span class="material-symbols-rounded">input</span>
                </abbr>
              </th>
            </tr>
          </thead>

          <tbody>
            <ng-container *ngFor="let fixation of fixations">
              <tr class="tr-align-center">
                <td class="sap" *ngIf="!hideColumns.includes('date')">
                  <ag-timestamp [date]="fixation.date" [showTime]="false"></ag-timestamp>
                </td>
                <td class="small sap" *ngIf="!hideColumns.includes('contract_reference')">
                  <samp [tooltip]="fixation.warnings?.length ? warnTemplate : null" container="body"
                    [adaptivePosition]="false">
                    <a *ngIf="fixation.contract?.reference; else none"
                      (click)="contractModal.show(fixation.contract.id)">{{ fixation.contract.reference }}</a>
                    <span *ngIf="fixation.warnings?.length"
                      class="differences material-symbols-rounded text-warning no-select hidden-xs">warning</span>
                    <ng-template #warnTemplate>
                      <div class="text-left">
                        <p class="small">
                          {{ "IMPORTER.WARNING.TITLE" | translate }}
                        </p>
                        <span *ngFor="let d of fixation.warnings; let last = last">
                          {{ "IMPORTER.WARNING." + d | translate }}
                          <ng-container *ngIf="!last"> ,</ng-container>
                        </span>
                      </div>
                    </ng-template>
                  </samp>
                </td>
                <td *ngIf="!hideColumns.includes('slate')">
                  <b *ngIf="fixation.slate; else none">{{ fixation.slate }}</b>
                </td>
                <td class="dlb drb text-right sap" *ngIf="!hideColumns.includes('price')">
                  <price-display [price]="fixation.price" [market]="company.market">
                  </price-display>
                </td>
                <td class="drb text-right sap" *ngIf="!hideColumns.includes('volume')">
                  <quantity-control [compact]="true" [quantity]="fixation.volume"></quantity-control>
                </td>
                <td class="text-right sap" *ngIf="!hideColumns.includes('slate_price')">
                  <price-display [price]="fixation.slate_price" [market]="company.market">
                  </price-display>
                </td>
                <td class="text-muted text-right sap" *ngIf="!hideColumns.includes('exchange_rate')">
                  <price-display [price]="fixation.exchange_rate" [market]="company.market">
                  </price-display>
                </td>
                <td class="sap small" *ngIf="!hideColumns.includes('due_date')">
                  <date-md *ngIf="fixation.due_date; else none" [date]="fixation.due_date" [style]="true"></date-md>
                </td>
                <td class="sap hidden-xs" *ngIf="!hideColumns.includes('observations')">
                  <i *ngIf="fixation.observations" class="material-symbols-rounded icon-gray"
                    [tooltip]="fixation.observations" container="body" placement="left">comment</i>
                </td>
                <td *ngIf="!hideColumns.includes('source')" class="sap">
                  <span *ngIf="fixation.import_channel_id; let source" class="micro text-muted">{{ "IMPORTER.SOURCE." +
                    source | translate }}</span>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <!-- No data -->
      <div class="no-data" *ngIf="fixations && fixations.length === 0">
        <i class="material-symbols-rounded">not_interested</i>
        {{ "CONTRACTS.NO_FIXATION_DATA" | translate }}
      </div>
    </div>
  </ng-container>

  <fixation-request-table *ngIf="viewMode === 'fixation-requests'" [company]="company">
  </fixation-request-table>
</ng-container>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>